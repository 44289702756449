.container.contact__container {
  width: 58%;
  display: grid;
  gap: 12%;
  grid-template-columns: 30% 58%;
}

.contact__options {
  flex-direction: column;
  display: flex;
  gap: 1.2rem;
}

.contact__option {
  padding: 1.2rem;
  border-radius: 1.2rem;
  background: var(--color-bg-variant);
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: var(--color-primary-variant);
  background: transparent;
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/*-----Form------*/

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  background: transparent;
  color: var(--color-white);
}

/*------MEDIA QUERIES(MEDIUM DEVICES)------*/

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/*------MEDIA QUERIES(SMALL DEVICES)------*/

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}